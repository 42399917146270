.label {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  overflow: hidden;
}

.input[type='color'] {
  -webkit-appearance: none;
  border: none;
  background: #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  overflow: hidden;
  outline: none;
  cursor: inherit;
}
.circle {
  position: absolute;
  left: 3px;
  top: 3px;
  width: 12px;
  height: 12px;
  border: #fff 2px solid;
  border-radius: 50%;
  cursor: inherit;
}

/* -webkit */
.input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}

.input[type='color']::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

/* firefox */
.input[type='color']::-moz-focus-inner {
  border: none;
  padding: 0;
  border-radius: 50%;
}

.input[type='color']::-moz-color-swatch {
  border: none;
  border-radius: 50%;
  height: 140px;
}
